<template>
	<div>
		<s-crud
			:config="config"
			:filter="filter"
			title="Costo Transporte"
			add
			edit
			remove
			@save="save($event)"
			@rowSelected="rowSelected($event)"
		>
			<template v-slot="props">
				<v-container>
					<v-row justify="center">
						<v-col lg="3" md="3" cols="3" class="mt-0 pb-0">
							<s-select-generic
								label="Zona"
								itemtext="ZonName"
								itemvalue="ZonID"
								id="ZonName"
								full
								autocomplete
								:config="configZon"
								v-model="props.item.ZonID"
							>
							</s-select-generic>
						</v-col>
						<v-col lg="3" md="3" cols="6" class="mt-0 pb-0">
							<s-select-definition label="Cultivo" :def="1173" v-model="props.item.TypeCultive">
							</s-select-definition>
						</v-col>
						<v-col lg="3" md="3" cols="6" class="mt-0 pb-0">
							<s-select-definition :def="1229" label="Costo" v-model="props.item.TypeCoste">
							</s-select-definition>
						</v-col>
						<v-col lg="1" md="1" cols="1" class="mt-0 pb-0">
							<v-checkbox
								label="Aplica ruta"
								v-model="ApplyRoute"
								@change="changeApplyRoute($event)"
							></v-checkbox>
						</v-col>
						<v-col lg="1" md="1" cols="1" class="mt-0 pb-0">
							<v-checkbox
								label="Aplica Jabas"
								v-model="ApplyJabas"
								@change="changeApplyJabas($event)"
							></v-checkbox>
						</v-col>
						<v-col lg="1" md="1" cols="1" class="mt-0 pb-0">
							<v-checkbox
								label="Precio adicional"
								v-model="ApplyAditional"
								@change="changeApplyAditional($event)"
							></v-checkbox>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col v-if="ApplyRoute" cols="3" lg="3" md="3" class="mt-0 pb-0">
							<!-- <s-text
								decimal
								label="KM"
								v-model="props.item.TtcKm"
								ref="textTtckm"
							></s-text> -->
							<s-select-definition
								label="KM" 
								:def="1197"
								v-model="props.item.TypeKM"
							>
							</s-select-definition>
						</v-col>
						<v-col cols="6" :lg="ApplyRoute ? 3 : 6" md="3" class="mt-0 pb-0">
							<s-select-definition
								label="Tipo Transporte"
								:def="1132"
								:dgr="44"
								v-model="props.item.TypeModel"
							></s-select-definition>
						</v-col>
						<v-col cols="6" lg="2" md="2" class="mt-0 pb-0">
							<s-text
								:min="1"
								number
								label="Capacidad"
								v-model="props.item.TtcCapacity"
								ref="TtcCapacity"
							></s-text>
						
						</v-col>
						<v-col cols="12" lg="2" md="2" class="mt-0 pb-0">
							<s-text
								decimal
								label="Costo"
								v-model="props.item.TtcCost"
								ref="textTtcCost"
							></s-text>
						</v-col>
						<v-col cols="12" v-if="ApplyAditional" lg="2" md="2" class="mt-0 pb-0">
							<s-text
								decimal
								label="Adicional"
								v-model="props.item.TtcAditional"
								ref="textTtcAditional"
							></s-text>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sTruckTransportCostService from "@/services/Technicalassistance/TruckTransportCostService.js";
	import _sTransportPriceService from "../../../services/Technicalassistance/TransportPriceService";

	export default {
		data() {
			return {
				ApplyRoute: true,
				ApplyAditional: false,
				ApplyJabas: false,
				filter: {},
				config: {
					model: {
						TtcID: "ID",
						TtcAditional: "decimal"
					},
					service: _sTruckTransportCostService,
					headers: [
						{ text: "ID", value: "TtcID" },
						{ text: "Cultivo", value: "TypeCultiveName" },
						{ text: "Costo", value: "TypeCosteName" },
						{ text: "KM", value: "TypeKMName" },
						{ text: "Tipo Veh.", value: "TypeModelName" },
						{ text: "Capacidad.", value: "TtcCapacity" },
						{ text: "Precio", value: "TtcCost" },
						{ text: "Precio Adicional", value: "TtcAditional" },
						{ text: "Zona", value: "ZonName" },
					],
				},
				configZon: {}
			};
		},

		methods: {
			save(item) {

				item.SecStatus = 1
				item.TtcApplyRoute = this.ApplyRoute ? 1 : 0;
				item.TtcApplyJabas = this.ApplyJabas ? 1 : 0;
				item.TtcApplyAditional = this.ApplyAditional ? 1 : 0;
				item.UsrCreateID = this.$fun.getUserID()
				item.UsrUpdateID = this.$fun.getUserID()

				console.log("Guardar", item);

				if (item.TypeCultive == null) {
					this.$fun.alert("Seleccione Cultivo", "warning")
					return;
				}

				if (item.TypeCoste == null) {
					this.$fun.alert("Seleccione Costo", "warning")
					return;
				}
				
				if(item.ApplyJabas != 1){
					if (item.TypeKM == null) {
						this.$fun.alert("Seleccione KM", "warning")
						return;
					}
				}

				if (item.TtcCapacity == "" || item.TtcCapacity == 0) {
					this.$refs.TtcCapacity.error("Ingrese Capacidad");
					return;
				}

				
				if (item.TtcCost == "" || item.TtcCost == 0) {
					this.$refs.textTtcCost.error("Ingrese Costo");
					return;
				}

				if(this.ApplyAditional && item.TtcAditional == 0){
					this.$refs.textTtcAditional.error("Ingrese Adicional");
					return;
				}
				item.save();
			},

			getZon()
			{
				this.configZon = {
					url: _sTransportPriceService.GetZon(),
					title: this.label,
					params: { search: "", requestID: this.$fun.getUserID() },
				};
			},

			changeApplyRoute(item)
			{
				item ? this.ApplyJabas= false : this.ApplyJabas = true;
			},

			changeApplyJabas(item)
			{
				item ? this.ApplyRoute = false : this.ApplyRoute = true;
			},

			changeApplyAditional(item)
			{
				
			},

			rowSelected(row)
			{
				if(row.length > 0){
					row = row[0];
					row.TtcApplyRoute == 1 ? this.ApplyRoute = true : this.ApplyRoute = false;
					row.TtcApplyJabas == 1 ? this.ApplyJabas = true : this.ApplyJabas = false;
					row.TtcApplyAditional == 1 ? this.ApplyAditional = true : this.ApplyAditional = false;
				}
			}
		},

		created () {
			this.getZon();
		},
	};
</script>